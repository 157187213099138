import createAxios from '@/utils/axiosConfig'
import qs from "qs";

const instance = createAxios()
instance.defaults.baseURL = process.env.VUE_APP_BASE_URL
instance.headers = {
  "Content-Type": "application/json",
}

// 1登录
export function checkLogin(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/login?" + decodeURI(postData));
}
// 2侧边栏
export function munuList(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/nav?" + decodeURI(postData));
}
// 3学校授权列表
export function authList(param) {
  const postData = qs.stringify(param);
  return instance.post("/auth/authSchool?" + decodeURI(postData));
}
// 3.查询所有学校档案列表学校授权情况
export function schoolArchivesList(param) {
  const postData = qs.stringify(param);
  return instance.post("/auth/schoolArchivesList?" + decodeURI(postData));
}
// 4取消学校授权
export function deleteAuthSchool(param) {
  const postData = qs.stringify(param);
  return instance.post("/auth/deleteAuthSchool?" + decodeURI(postData));
}
// 5增加学校授权
export function addAuthSchool(param) {
  const postData = qs.stringify(param);
  return instance.post("/auth/addAuthSchool?" + decodeURI(postData));
}
// 6查询个人授权情况
export function getTargetUserAuth(param) {
  const postData = qs.stringify(param);
  return instance.post("/auth/getTargetUserAuth?" + decodeURI(postData));
}
// 7取消个人功能授权接口
export function deleteTargetUserAuth(param) {
  const postData = qs.stringify(param);
  return instance.post("/auth/deleteTargetUserAuth?" + decodeURI(postData));
}
// 8增加个人功能授权接口
export function addTargetUserAuth(param) {
  const postData = qs.stringify(param);
  return instance.post("/auth/addTargetUserAuth?" + decodeURI(postData));
}
// 9.获取学校列表
export function getSchoolListSimple(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/getSchoolListSimple?" + decodeURI(postData));
}


// 11.更新密码（8.0）
export function updatePassword(param) {
  const postData = qs.stringify(param);
  return instance.post("/auth/updatePassword?" + decodeURI(postData));
}

// 12.根据学校获取教师列表
export function getTeacherList(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/teacher/getTeacherList?" + decodeURI(postData));
}

// 驾驶舱
// 13.获取学校综合信息
export function getSchoolGeneral(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/getSchoolGeneral?" + decodeURI(postData));
}
// 14.获取学校综合信息-2教师学生出勤统计数据
export function getSchoolRepInfo(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/getSchoolRepInfo?" + decodeURI(postData));
}

// 数据采集
// 15.采集-获取采集模型模板列表接口
export function getCollectModelList() {
  return instance.post("/core/collect/getCollectModelList?");
}
// 16.采集-获取采集任务列表接口
export function getCollectList() {
  return instance.post("/core/collect/getCollectList?");
}
// 17.采集-下载采集模板Excel文件接口
export function downloadCollect(param) {
  return instance.post("/core/collect/downloadCollect", param);
}
// 18.采集-上传采集数据Excel文件接口
export function uploadCollect(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/collect/uploadCollect?" + decodeURI(postData));
}
// 19.采集-获取班级
export function getClassBySchool(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/getClassBySchool?" + decodeURI(postData));
}


// 20.获取某学校学生列表page接口
export function getStudentBySchool(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/student/getStudentBySchool?" + decodeURI(postData));
}
// 21.获取学生个人档案信息接口
export function getStudentInfo(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/student/getStudentInfoNew?" + decodeURI(postData));
}
// 22.获取某学校教师分页接口
export function getTeacherPage(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/teacher/getTeacherPage?" + decodeURI(postData));
}
// 23.获取教师个人档案信息接口
export function getTeacherInfo(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/teacher/getTeacherInfo?" + decodeURI(postData));
}
// 24.获取某学校班级分页接口
export function getClassPage(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/getClassPage?" + decodeURI(postData));
}
// 25.获取班级大脑知识点接口
export function getBrainKnowledge(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/getBrainKnowledge?" + decodeURI(postData));
}
// 26.获取班级大脑左边数据接口
export function getBrainLeft(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/getBrainLeft?" + decodeURI(postData));
}
// 27.获取大脑右边数据
export function getBrainWright(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/getBrainWright?" + decodeURI(postData));
}

// 28.通过班级获取学生列表接口
export function getStudentByClassId(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/student/getStudentByClassId?" + decodeURI(postData));
}

// 29.上传学校头像 
export function upLoadSchoolAvatar(param) {
  return instance.post("/core/upLoadSchoolAvatar", param);
}

// 30.更新学校基础信息
export function updateSchool(param) {
  return instance.post("/core/updateSchool", param);
}

// 31.档案-获取学校基础信息
export function getSchoolBase(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/getSchoolBase?" + decodeURI(postData));
}

// 32.新增校内新闻
export function addSchoolNews(param) {
  return instance.post("/core/addSchoolNews", param);
}

// 32.更新学校简介
export function updateSchoolInfo(param) {
  return instance.post("/core/updateSchoolInfo", param);
}

// 35.采集-上传采集数据Excel文件接口2确认采集（2新预览）
export function uploadFile(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/collect/uploadFile?" + decodeURI(postData));
}

// 36.获取学校荣誉
export function getSchoolHonor(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/getSchoolHonor?" + decodeURI(postData));
}

// 37.新增学校荣誉
export function addSchoolHonor(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/addSchoolHonor", param);
}

// 38.更新学校荣誉
export function updateSchoolHonor(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/updateSchoolHonor", param);
}

// 39.删除学校荣誉
export function deleteSchoolHonor(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/deleteSchoolHonor?" + decodeURI(postData));
}

// 40.获取流动红旗分页
export function getRedFlag(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/triathlon/redFlag/getRedFlag?" + decodeURI(postData));
}

// 41.新增流动红旗
export function addRedFlag(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/triathlon/redFlag/addRedFlag", param);
}

// 42.更新流动红旗
export function updateRedFlag(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/triathlon/redFlag/updateRedFlag", param);
}

// 57.删除红旗
export function deleteRedFlag(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/triathlon/redFlag/deleteRedFlag?" + decodeURI(postData));
}


// 43.获取年段
export function getStageList(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/getStageList?" + decodeURI(postData));
}

// 44.根据年段获取年级
export function getGradeList(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/getGradeList?" + decodeURI(postData));
}

// 45.根据学校获取教师列表(简洁版)
export function getSimpleTeacherList(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/teacher/getSimpleTeacherList?" + decodeURI(postData));
}

// 46.新增班级
export function addClass(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/addClass", param);
}

// 47.删除班级
export function deleteClass(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/deleteClass?" + decodeURI(postData));
}

// 48.更新班级
export function updateClass(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/updateClass", param);
}

// 49.新增教师接口
export function addTeacher(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/teacher/addTeacher", param);
}

// 50.更新教师接口
export function updateTeacher(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/teacher/updateTeacher", param);
}

// 64.删除教师
export function deleteTeacher(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/teacher/deleteTeacher?" + decodeURI(postData));
}



// 52.获取年级班级级联
export function getClassLink(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/getClassLink?" + decodeURI(postData));
}

// 53.获取三项竞赛列表分页
export function getTriathlonPage(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/triathlon/getTriathlonPage?" + decodeURI(postData));
}

// 54.删除三项竞赛记录
export function deleteTriathlon(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/triathlon/deleteTriathlon?" + decodeURI(postData));
}

// 55.新增三项记录
export function addTriathlon(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/triathlon/addTriathlon", param);
}

// 更新三项
export function updateTriathlon(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/triathlon/updateTriathlon", param);
}


// 56.获取三项记录类型
export function getTriathlon(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/triathlon/getTriathlon?" + decodeURI(postData));
}

// 58.全校三项竞赛明细分析
export function repAllTriathlonDetail(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/triathlon/repAllTriathlonDetail?" + decodeURI(postData));
}
// 59.全校三项竞赛近问题突出班级立体分析
export function repAllTriathlon5Day(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/triathlon/repAllTriathlon5Day?" + decodeURI(postData));
}
// 60.近30日值周诊断分析(桑基图)
export function getTriathlonClassSangji(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/triathlon/getTriathlonClassSangji?" + decodeURI(postData));
}
// 61.值周榜上有名
export function getTriathlonClassTop(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/triathlon/getTriathlonClassTop?" + decodeURI(postData));
}
// 62.易容仪表&班荣班貌
export function getTriathlonBanrong(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/triathlon/getTriathlonBanrong?" + decodeURI(postData));
}
// 65.获取考试分页
export function pageTest(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/test/pageTest?" + decodeURI(postData));
}
// 66.增加考试
export function addTest(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/test/addTest", param);
}
// 67.更新考试
export function updateTest(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/test/updateTest", param);
}
// 68.删除考试
export function deleteTest(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/test/deleteTest?" + decodeURI(postData));
}
// 69.获取考试科目分页
export function pageTestSubject(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/test/pageTestSubject?" + decodeURI(postData));
}
// 70.增加考试科目
export function addTestSubject(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/test/addTestSubject", param);
}
// 71.更新考试科目
export function updateTestSubject(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/test/updateTestSubject", param);
}
// 72.删除考试科目
export function deleteTestSubject(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/test/deleteTestSubject?" + decodeURI(postData));
}
// 73.获取考试班级分页
export function pageTestClass(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/test/pageTestClass?" + decodeURI(postData));
}
// 74.增加考试班级
export function addTestClass(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/test/addTestClass", param);
}
// 75.更新考试班级
export function updateTestClass(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/test/updateTestClass", param);
}
// 76.删除考试班级
export function deleteTestClass(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/test/deleteTestClass?" + decodeURI(postData));
}
// 77.获取考试成绩分页
export function pageTestReport(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/test/pageTestReport?" + decodeURI(postData));
}
// 78.新增考试成绩
export function addTestReport(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/test/addTestReport", param);
}
// 79.删除考试成绩
export function deleteTestReport(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/test/deleteTestReport?" + decodeURI(postData));
}
// 80.更改考试成绩
export function updateTestReport(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/test/updateTestReport", param);
}


// 81.获取科目列表
export function getSubject(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/subject/getSubject?" + decodeURI(postData));
}

// 84.考试-确认上传考试成绩
export function uploadTestReportFile(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/test/uploadTestReportFile?" + decodeURI(postData));
}

// 85.
export function reportUserList(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/testReport/reportUserList?" + decodeURI(postData));
}

// 86.个人画像知识空间
export function reportUser(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/testReport/reportUser?" + decodeURI(postData));
}

// 87.个人画像-体育得分列表
export function testSport(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/testReport/testSport?" + decodeURI(postData));
}

// 88.个人画像-体育得分列表
export function testSportDetail(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/testReport/testSportDetail?" + decodeURI(postData));
}

export function getFE(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/fe/getFE?" + decodeURI(postData));
}
export function getFEAll(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/fe/getFEAll?" + decodeURI(postData));
}


export function getInstructionCenter(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/brainCenter/getInstructionCenter?" + decodeURI(postData));
}

export function getSchoolSum(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/brainCenter/getSchoolSum?" + decodeURI(postData));
}

export function getSchedulingLog(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/brainCenter/getSchedulingLog?" + decodeURI(postData));
}

export function getInstructionCenterList(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/brainCenter/getInstructionCenterList?" + decodeURI(postData));
}

export function getStageStudent(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/brainCenter/getStageStudent?" + decodeURI(postData));
}

export function AuthSchool(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/AuthSchool?" + decodeURI(postData));
}

export function getJzLogCourse(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/brainCenter/getJzLogCourse?" + decodeURI(postData));
}

// 群体画像-班级画像--班级列表
export function getClassList(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/brainClassController/getClassList?" + decodeURI(postData));
}
// 114.群体画像-班级画像--核心数据
export function getClassNum(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/brainClassController/getClassNum?" + decodeURI(postData));
}
// 115.群体画像-班级画像--班级详情
export function getClassInfo(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/brainClassController/getClassInfo?" + decodeURI(postData));
}
// 116.群体画像-班级画像--班级三项竞赛得分详情
export function getClassScoreDetail(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/brainClassController/getClassScoreDetail?" + decodeURI(postData));
}
// // 117.群体画像-班级画像--考试列表
// export function getTestList(param) {
//   const postData = qs.stringify(param);
//   return instance.post("/core/brainClassController/getTestList?" + decodeURI(postData));
// }

// 99.群体画像-全校师生画像-教师荣誉
export function getTeacherHonor(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/schoolAllTeacher/getTeacherHonor?" + decodeURI(postData));
}
// 100.群体画像-全校师生画像-教师荣誉饼图
export function honorPie(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/schoolAllTeacher/honorPie?" + decodeURI(postData));
}
// 101.群体画像-全校师生画像-教师职称饼图
export function jobTitlePie(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/schoolAllTeacher/jobTitlePie?" + decodeURI(postData));
}
// 102.群体画像-全校师生画像-教师总数和职称占比
export function getTeacher(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/schoolAllTeacher/getTeacher?" + decodeURI(postData));
}
// 103.群体画像-全校师生画像-各年龄段的教师数量
export function teacherAge(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/schoolAllTeacher/teacherAge?" + decodeURI(postData));
}
// 104.群体画像-全校师生画像-各工龄段的教师数量
export function teacherWorkAge(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/schoolAllTeacher/teacherWorkAge?" + decodeURI(postData));
}
// 105.群体画像-全校师生画像-男女教师分布情况
export function teacherGender(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/schoolAllTeacher/teacherGender?" + decodeURI(postData));
}
// 106.群体画像-全校师生画像-各个学历的教师数量
export function teacherEducation(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/schoolAllTeacher/teacherEducation?" + decodeURI(postData));
}
// 107.个体画像-教师画像-教师综合信息--查询教师列表
export function getTeacherListById(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/teacher/getTeacherListById?" + decodeURI(postData));
}
// 108.个体画像-教师画像--查询教师档案详细信息
export function getTeacherInfoById(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/teacher/getTeacherInfoById?" + decodeURI(postData));
}
// 109.个体画像-教师画像--查询教师任教时间
export function getTeacherWorkTimeById(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/teacher/getTeacherWorkTimeById?" + decodeURI(postData));
}
// 110.个体画像-教师画像--查询教师教育背景
export function getTeacherEduById(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/teacher/getTeacherEduById?" + decodeURI(postData));
}
// 111.个体画像-教师画像--根据老师id获取教师职称
export function getTeacherJobTitleById(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/teacher/getTeacherJobTitleById?" + decodeURI(postData));
}
// 112.个体画像-教师画像-查询老师荣誉
export function getTeacherExtHonorById(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/teacher/getTeacherExtHonorById?" + decodeURI(postData));
}
// 117.群体画像-班级画像--考试列表
export function getTestList(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/brainClassController/getTestList?" + decodeURI(postData));
}
// 118.群体画像-班级画像--考试成绩分析
export function getTestAnalyse(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/brainClassController/getTestAnalyse?" + decodeURI(postData));
}
// 119.群体画像-班级画像--值周得分情况
export function getZhiZhouScore(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/brainClassController/getZhiZhouScore?" + decodeURI(postData));
}
// 121.群体画像-班级画像--科目列表
export function getSubjectList(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/brainClassController/getSubjectList?" + decodeURI(postData));
}
// 120.群体画像-班级画像--某次考试各科成绩分析
export function getTestSubjectAnalyse(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/brainClassController/getTestSubjectAnalyse?" + decodeURI(postData));
}
// 122.个体画像-学生画像 -五育雷达图
export function getAllEducation(param){
	const postData = qs.stringify(param);
	// ;
	return instance.post("/core/fe/getFEAll1?" + decodeURI(postData)); 
}
// 123.个体画像-学生画像 -五育详情页分析
export function getStudentXiangqing(param){
	const postData = qs.stringify(param);
	// ;
	return instance.post("/core/student/getStudentFEDetail?" + decodeURI(postData)); 
}
// 124.个体画像-教师画像-荣誉雷达图
export function getTeacherHonour(param){
	const postData = qs.stringify(param);
	// ;
	return instance.post("/core/teacher/getTeacherType?" + decodeURI(postData)); 
}
// 125.个体画像-教师画像-教师基本信息
export function getTeacherxinxi(param){
	const postData = qs.stringify(param);
	// ;
	return instance.post("/core/teacher/getTeacherMessage?" + decodeURI(postData)); 
}
// 126.个体画像-教师画像-教师荣誉详情
export function getHonor(param){
	const postData = qs.stringify(param);
	// ;
	return instance.post("/core/teacher/getHonor?" + decodeURI(postData)); 
}
// 127.个体画像-学生画像-单科成绩折线图
export function testSubject(param){
	const postData = qs.stringify(param);
	// ;
	return instance.post("/core/testReport/testSubject?" + decodeURI(postData)); 
}
// 128.个体画像-学生画像-优劣势比较
export function getStudentFEComparison(param){
	const postData = qs.stringify(param);
	// ;
	return instance.post("/core/student/getStudentFEComparison?" + decodeURI(postData)); 
}
// 129.个体画像-学生画像-本周和上周比较
export function getStudentComparison(param){
	const postData = qs.stringify(param);
	// ;
	return instance.post("/core/student/getStudentComparison?" + decodeURI(postData)); 
}
// 130.个体画像-学生画像-老师评分详情
export function getStudentDetails(param){
	const postData = qs.stringify(param);
	// ;
	return instance.post("/core/student/getStudentDetails?" + decodeURI(postData)); 
}
// 131.个体画像-学生画像-学生荣誉雷达图
export function getStudentType(param){
	const postData = qs.stringify(param);
	// ;
	return instance.post("/core/student/getStudentType?" + decodeURI(postData)); 
}
// 132.群体画像-班级画像-班级成绩分析
export function reportClass(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/testReport/reportClass?" + decodeURI(postData));
}
// 133.个体画像-教师画像-教师标签
export function getTeachersposition(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/teacher/getTeachersposition?" + decodeURI(postData));
}

// 133.个体画像-教师画像-教育背景
export function getTeacherEducational(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/teacher/getTeacherEducational?" + decodeURI(postData));
}
// 134.个体画像-教师画像-教师五育评分
export function getTeacherEvaluate(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/teacher/getTeacherEvaluate?" + decodeURI(postData));
}
// 135.群体画像-班级画像-班级荣誉
export function getClassHonor(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/student/getClassHonor?" + decodeURI(postData));
}
// 136.个体画像-教师画像-课务
export function getClassAffairs(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/teacher/getClassAffairs?" + decodeURI(postData));
}
// 137.个体画像-学生画像-优差对比
export function studentComparisonView(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/student/studentComparisonView?" + decodeURI(postData));
}
// 138.群体画像-班级画像-班级五育信息
export function getClassFE(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/fe/getClassFE?" + decodeURI(postData));
}

export function getStudentHonor(param){
	const postData = qs.stringify(param);
	// ;
	return instance.post("/core/student/getStudentHonor?" + decodeURI(postData)); 
}
export function reportStudent(param) {
  const postData = qs.stringify(param);
  return instance.post("/core/testReport/reportStudent?" + decodeURI(postData));
}
// 驾驶舱校园文化-学校基本信息
export function schoolmessage(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/schoolmessage?" + decodeURI(postData));
}
// 驾驶舱校园文化-校历
export function schoolcalendar(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/schoolcalendar?" + decodeURI(postData));
}
// 驾驶舱人员架构-学生性别分布
export function number(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/student/number?" + decodeURI(postData));
}
// 驾驶舱人员架构-年级段学生人数分布
export function selgradenumber(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/student/selgradenumber?" + decodeURI(postData));
}
// 驾驶舱人员架构-教师年龄分布
export function teacherage(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/teacher/teacherage?" + decodeURI(postData));
}
// 驾驶舱教学管理-今日学生考勤
export function studentcheck(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/studentcheck?" + decodeURI(postData));
}
// 驾驶舱教学管理-今日老师考勤
export function teachercheck(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/teachercheck?" + decodeURI(postData));
}
// 驾驶舱教学管理-班级值周概况
export function weekcondition(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/triathlon/weekcondition?" + decodeURI(postData));
}
// 驾驶舱教学管理-上周满分班级
export function markclass(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/student/markclass?" + decodeURI(postData));
}

// 驾驶舱综合管理-本周调代课
export function weeksubstitute(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/synthesize/weeksubstitute?" + decodeURI(postData));
}
// 驾驶舱综合管理-本周场馆预约
export function weekvenue(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/synthesize/weekvenue?" + decodeURI(postData));
}
// 驾驶舱综合管理-本周在线直播课
export function weeklive(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/synthesize/weeklive?" + decodeURI(postData));
}
// 驾驶舱综合管理-本周物品保修
export function weekgoods(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/synthesize/weekgoods?" + decodeURI(postData));
}
// 驾驶舱综合管理-兴趣课程
export function weekinterest(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/synthesize/weekinterest?" + decodeURI(postData));
}
// 驾驶舱综合管理-本周物品领用
export function weekreceive(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/synthesize/weekreceive?" + decodeURI(postData));
}
// 驾驶舱综合管理-课后服务
export function weekafter(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/synthesize/weekafter?" + decodeURI(postData));
}
// 驾驶舱综合管理-本周家校沟通
export function weekfamilyschool(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/synthesize/weekfamilyschool?" + decodeURI(postData));
}
// 驾驶舱综合管理-本周家长云学堂
export function weekpatriarchyxt(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/synthesize/weekpatriarchyxt?" + decodeURI(postData));
}
// 教学管理-上周五育评价概况
export function getLastWeekFY(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/getLastWeekFY?" + decodeURI(postData));
}
// 教学管理-本周作业概况
export function getWeekjob(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/weekjob?" + decodeURI(postData));
}
// 教学管理-本周五育评价概况
export function getThisWeekFY(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/getThisWeekFY?" + decodeURI(postData));
}
// 校园文化/学校组织架构
export function getSchoolRole(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/getSchoolRole?" + decodeURI(postData));
}
// 校园文化/校园新闻
export function getSchoolNews(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/getSchoolNews?" + decodeURI(postData));
}
// 校园文化/学校历程
export function getSchoolCourse(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/getSchoolCourse?" + decodeURI(postData));
}
// 校园文化/学校荣誉奖项
export function getSchoolHonors(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/getHonor?" + decodeURI(postData));
}
// 校园文化/学校本周大项工作安排
export function getCycle(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/getCycle?" + decodeURI(postData));
}
// 驾驶舱人员架构-教师来校时间分布
export function prompt(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/teacher/prompt?" + decodeURI(postData));
}
// 人员架构/学生荣誉奖项
export function getSchoolStudentHonor(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/getSchoolStudentHonor?" + decodeURI(postData));
}
// 人员架构/教师性别分布
export function getTeacherGender(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/getTeacherGender?" + decodeURI(postData));
}
// 人员架构/教师学位分布
export function getDegree(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/getDegree?" + decodeURI(postData));
}
// 人员架构/教师政治面貌分布
export function getTeacherPoliticalLandscape(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/getTeacherPoliticalLandscape?" + decodeURI(postData));
}
// 人员架构/教师职称分布
export function jobTitlePie1(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/schoolAllTeacher/jobTitlePie?" + decodeURI(postData));
}
// 人员架构/教师专业技术等级分布
export function getPostgrades(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/getPostgrades?" + decodeURI(postData));
}
// 人员架构/教师荣誉奖项
export function getTeacherLevel(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/getTeacherLevel?" + decodeURI(postData));
}
// 人员架构/教师学历分布
export function getHighestEducation(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/getHighestEducation?" + decodeURI(postData));
}
// 人员架构/教师临近退休人数
export function getRetire(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/getRetire?" + decodeURI(postData));
}
// 人员架构/教师在编分
export function getCertification(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/getCertification?" + decodeURI(postData));
}
// 驾驶舱教学管理-昨日值周详情
export function weekdetail(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/triathlon/weekdetails?" + decodeURI(postData));
}
// 驾驶舱教学管理-2021学年上学期体测
export function constitution(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/constitution?" + decodeURI(postData));
}
// 驾驶舱教学管理-2021学年上学期期中考试
export function examscore(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/examscore?" + decodeURI(postData));
}

// 驾驶舱教学管理-本周课节
export function weeklessons(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/weeklessons?" + decodeURI(postData));
}
// 系统管理-学校模糊查询
export function getSchoolLike(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/getSchoolLike?" + decodeURI(postData));
}
// 驾驶舱首页-校园新闻-新闻列表
export function getSchoolNewsDesc(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/getSchoolNewsDesc?" + decodeURI(postData));
}
// 驾驶舱首页-校园新闻-新闻列表-更多
export function getSchoolNewsList(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/getSchoolNewsList?" + decodeURI(postData));
}
// 驾驶舱首页-教师荣誉-奖项类型
export function getTeacherHonorType(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/teacher/teacherHonor?" + decodeURI(postData));
}
// 驾驶舱首页-学生荣誉-类型
export function getStudentHonorByType(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/student/studentHonor?" + decodeURI(postData));
}
// 驾驶舱首页-学生荣誉-班级
export function getStudentHonorByGrade(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/student/HonorGrade?" + decodeURI(postData));
}
// 驾驶舱首页-获取全部年级
export function getStudentHonorGrade(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/student/grade?" + decodeURI(postData));
}
// 驾驶舱首页-本周五育评价排行前十
export function getStudentRvaluateRanking(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/student/rvaluateRankingV2?" + decodeURI(postData));
}
// 驾驶舱首页-本周五育评价柱状图
export function getStudentEvaluateHistogram(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/student/evaluateHistogramV2?" + decodeURI(postData));
}
// 驾驶舱首页-班级值周
export function getStudentClassZhiZhou(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/student/classZhiZhou?" + decodeURI(postData));
}
// 驾驶舱首页-校园公告
export function getAnnouncement(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/announcement?" + decodeURI(postData));
}
// 驾驶舱首页-喜报
export function getSchoolHonorList(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/getSchoolHonorList?" + decodeURI(postData));
}
// 驾驶舱首页-学生荣誉
export function getStudentHonorAll(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/student/studentHonorAll?" + decodeURI(postData));
}
// 新闻详情
export function getSchoolNewsDetail(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/getSchoolNewsDetail?" + decodeURI(postData));
}
// 驾驶舱首页-班级值周   最新版
export function getStudentZhiZhouEvaluate(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/student/ZhiZhouEvaluate?" + decodeURI(postData));
}
  // ======> v2版本
  export function getStudentZhiZhouEvaluateV2(param) {
    const postData = qs.stringify(param);
    
    return instance.post("/core/student/ZhiZhouEvaluate?" + decodeURI(postData));
  } 

// 驾驶舱首页-喜报    最新版
export function getBulletin(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/message/getBulletin?" + decodeURI(postData));
}
// 驾驶舱首页-今日菜单
export function getFoodMenu(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/food/getFoodMenu?" + decodeURI(postData));
}
// 驾驶舱首页-校园公告  最新版
export function getMessageNews(param) {
  const postData = qs.stringify(param);
  
  return instance.post("/core/message/getNews?" + decodeURI(postData));
}

//
export const getloglist=()=>instance.post('http://120.26.80.169:8099/wuge/core/log/all?')
//添加日志
export const addloglist=(data)=> {
  const postData = qs.stringify(data);
  instance.post('http://120.26.80.169:8099/wuge/core/log/log?' + decodeURI(postData))
}