<template>
	<!--  <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
	<!-- 21321 -->
	<!-- <hello></hello> -->
	<router-view />

</template>
<script>

</script>

<style lang="scss">
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		background: #D8D8D8;
	}
</style>
