import {
	createRouter,
	createWebHistory,
	createWebHashHistory
} from 'vue-router'


const routes = [{
		path: "/",
		redirect: "/login",
	},
	{
		path: "/login",
		name: "Login",
		component: () =>
			import( /* webpackChunkName: "about" */ "@/views/login/login"),
	},
	{
		path: "/loading",
		name: "loading",
		component: () =>
			import( /* webpackChunkName: "about" */ "@/views/login/loading"),
	},
	{
		name: "新闻",
		path: "/newsDetail/:newsId",
		props: true,
		component: () => import("@/views/dataCenter/cockpitIndex/newsDetail"),
	},
	{
		path: "/index",
		name: "Layout",
		component: () =>
			import( /* webpackChunkName: "about" */ "@/components/Layout"),
		children: [{
				name: "",
				path: "/cockpit",
				component: () => import("@/views/dataCenter/cockpitIndex/cockpitv2"),
			},
			{
				name: "",
				path: "/jiaoxueguanli",
				component: () => import("@/views/dataCenter/cockpitIndex/jiaoxueguanli"),
			},
			{
				name: "",
				path: "/renyuanjiagou",
				component: () => import("@/views/dataCenter/cockpitIndex/renyuanjiagou"),
			},
			{
				name: "",
				path: "/zongheguanli",
				component: () => import("@/views/dataCenter/cockpitIndex/zongheguanli"),
			},
			{
				name: "",
				path: "/school",
				component: () => import("@/views/dataCenter/cockpitIndex/schoolv2"),
			},
			{
				name: "地图",
				path: "/mapCenter",
				component: () => import("@/views/mapCenter"),
			},
			// 数据资产
			{
				name: "驾驶舱",
				path: "/cockpit",

				component: () => import("@/views/dataCenter/dataAssets/cockpit"),
			},
			{
				name: "题库",
				path: "/dataCenter/questionBank",
				component: () => import("@/views/dataCenter/dataAssets/questionBank"),
			},
			{
				name: "校园大脑",
				path: "/dataCenter/schoolBrain",
				component: () => import("@/views/dataCenter/schoolBrain"),
			},
			// 个体画像
			{
				name: "学生画像",
				path: "/dataCenter/studentPortrait",
				component: () => import("@/views/dataCenter/unitPortrait/studentPortrait"),
			},
			{
				name: "教师画像",
				path: "/dataCenter/teacherPortrait",
				component: () => import("@/views/dataCenter/unitPortrait/teacherPortrait"),
			},
			{
				name: "体质健康分析",
				path: "/dataCenter/healthAnalysis",
				component: () => import("@/views/dataCenter/unitPortrait/healthAnalysis"),
			},
			// 群体画像
			{
				name: "全体教师画像",
				path: "/dataCenter/allTeachersPortrait",
				component: () => import("@/views/dataCenter/groupPortrait/allTeachersPortrait"),
			},
			{
				name: "班级画像",
				path: "/dataCenter/classPortrait",
				component: () => import("@/views/dataCenter/groupPortrait/classPortrait"),
			},
			{
				name: "全校值周分析",
				path: "/dataCenter/weeklyAnalysis",
				component: () => import("@/views/dataCenter/groupPortrait/weeklyAnalysis"),
			},
			// 数据采集
			{
				name: "采集监控",
				path: "/dataCenter/collectionMonitor",
				component: () => import("@/views/dataCenter/dataCollection/collectionMonitor"),
			},
			{
				name: "新增采集",
				path: "/dataCenter/newCollection",
				component: () => import("@/views/dataCenter/dataCollection/newCollection"),
			},
			{
				name: "采集扩展",
				path: "/dataCenter/collectionExtend",
				component: () => import("@/views/dataCenter/dataCollection/collectionExtend"),
			},

			{
				name: "红旗颁奖",
				path: "/dataCenter/collectionFlag",
				component: () => import("@/views/dataCenter/dataCollection/collectionFlag"),
			},

			{
				name: "三项竞赛",
				path: "/dataCenter/collectionThree",
				component: () => import("@/views/dataCenter/dataCollection/collectionThree"),
			},

			{
				name: "考试",
				path: "/dataCenter/test",
				component: () => import("@/views/dataCenter/dataCollection/test"),
			},
			{
				name: "考试科目",
				path: "/dataCenter/testSubject",
				component: () => import("@/views/dataCenter/dataCollection/test/testSubject"),
			},
			{
				name: "考试班级",
				path: "/dataCenter/testClass",
				component: () => import("@/views/dataCenter/dataCollection/test/testClass"),
			},
			{
				name: "考试成绩",
				path: "/dataCenter/testScore",
				component: () => import("@/views/dataCenter/dataCollection/test/testScore"),
			},
			{
				name: "添加项目",
				path: "/dataCenter/test/add",
				component: () => import("@/views/dataCenter/dataCollection/test/add"),
			},

			// 服务中心
			{
				name: "中台服务",
				path: "/serviceCenter/stationService",
				component: () => import("@/views/serviceCenter/stationService"),
			},
			{
				name: "学生档案",
				path: "/serviceCenter/studentFiles",
				component: () => import("@/views/serviceCenter/fileManage/studentFiles"),
			},
			{
				name: "教师档案",
				path: "/serviceCenter/teacherFiles",
				component: () => import("@/views/serviceCenter/fileManage/teacherFiles"),
			},
			{
				name: "班级档案",
				path: "/serviceCenter/classFiles",
				component: () => import("@/views/serviceCenter/fileManage/classFiles"),
			},
			{
				name: "学校档案",
				path: "/serviceCenter/schoolFiles",
				component: () => import("@/views/serviceCenter/fileManage/schoolFiles"),
			},
			{
				name: "学校档案编辑",
				path: "/serviceCenter/schoolFiles/edit",
				component: () => import("@/views/serviceCenter/fileManage/schoolEdit"),
			},
			{
				name: "系统管理",
				path: "/serviceCenter/systemManage",
				component: () => import("@/views/serviceCenter/systemManage"),
			},
			{
				name: "能力授权",
				path: "/serviceCenter/systemManage/ability",
				component: () => import("@/views/serviceCenter/systemManage/ability"),
			},
			{
				name: "系统日志",
				path: "/serviceCenter/loglist",
				component: () => import("@/views/serviceCenter/systemManage/loglist"),
			},
			{
				name: "个人设置",
				path: "/setting",
				component: () => import("@/views/setting"),
			},
		]
	},
];

const router = createRouter({
	history: createWebHashHistory(process.env.BASE_URL),
	routes
})


import {addloglist} from '../network/request'
router.beforeEach((to, from, next) => {
	// next();
	console.log(to.path)
	//添加日志loginInfo
	const loginInfo = localStorage.getItem('loginInfo');
	let userinfo
	if(loginInfo){
		userinfo=JSON.parse(loginInfo)
		console.log( userinfo )
		addloglist({type:1,target:to.path,userId:userinfo.userId,detail:`进入${to.path}`});
	}
	next();
	// if (to.path == "/login" || to.path == "/waitReview") {
	// 	// 登录或者注册才可以往下进行
	// 	next();
	// } else {
	// 	// 获取 token
	// 	const token = localStorage.getItem('token');
	// 	// 是否完善学校信息
	// 	// const isSchoolInfo = localStorage.getItem('isSchoolInfo');
	// 	// token 不存在
	// 	if ((token === null || token === '') && to.path != "/waitReview") {
	// 		next('/login');
	// 	}
	// 	// else if (isSchoolInfo == 2 && to.path != "/schoolInfo") {
	// 	// 	next('/schoolInfo');
	// 	// } 
	// 	else {
	// 		next();
	// 	}
	// }
})
export default router
