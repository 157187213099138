import {
	ElNotification
} from 'element-plus'
import axios from 'axios'
import {
	router
} from "vue";
import {addloglist} from '../network/request'

export default function() {
	//基本配置
	let request = axios.create()
	request.defaults.timeout = 10000
	// request.defaults.withCredentials = true
	// request.defaults.credentials = 'include' // 默认请求是否带上cookie

	const noAuthorUrlArr = ['/core/login'];

	//请求拦截
	request.interceptors.request.use(
		function(config) {

			const noAuthor = noAuthorUrlArr.includes(config.url.split('?')[0]);
			const token = localStorage.getItem("token")
			// if(!noAuthor){
			// 	const token = localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : ''
			// }

			if (!noAuthor && !config.data) {
				config.url = `${config.url}`
				config.headers.token = token;
			}
			if (config.data) {
				config.headers.responseType = "blob"
				config.url = `${config.url}`
				config.headers.token = token;
			}
			return config
		},
		function(error) {
			// Do something with request error
			return error
		}
	)

	//响应拦截
	request.interceptors.response.use(
		function(response) {
			const loginInfo = localStorage.getItem('loginInfo');
			let userinfo
			if(loginInfo&&!response.config.url.includes('wuge/core/log/log')){
				userinfo=JSON.parse(loginInfo)
				// console.log('response',response);
				addloglist({type:2,target:response.config.url.split('?')[0],userId:userinfo.userId,detail:`请求接口${response.config.url.split('?')[0]}`});
			}
			return response.data
		},
		function(error) {
			let {
				response
			} = error
			if (response) {
				switch (response.status) {
					case 404:
						break
				}
				return error
			} else {
				// 没有返回
				if (!window.navigator.onLine) {
					// 断网处理...
					ElNotification.error({
						title: '错误',
						message: '请重新登录，认证失败' + response.status,
					})
					return
				}
				ElNotification.error({
					title: '错误',
					message: '请重新登录，认证失败' + response.status,
				})
				return error
			}
		}
	)
	return request
}
