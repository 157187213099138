import {
	createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import installElementPlus from './plugins/element'
import Moment from 'moment'
import 'echarts-gl'
import "@/assets/reset.css"
import "@/assets/animate.css"
import "@/assets/common.scss"

import htmlToPdf from '@/utils/htmlToPdf'
import vue3SeamlessScroll from 'vue3-seamless-scroll'

import * as api from './network/request'
import * as echarts from 'echarts'
import * as Elicons from "@element-plus/icons-vue";
const app = createApp(App);

for (const name in Elicons) {
	app.component(name, Elicons[name]);
}


// app.filter('formatDate', function(value) {
// 	return Moment(value).format('YYYY-MM-DD HH:mm:ss')
// })
// for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
// 	app.component(key, component)
// }

installElementPlus(app)

// for ([name, comp] of Object.entries(ElementPlusIconsVue)) {
// 	app.component(name, comp);
// }
app.config.globalProperties.$api = api
app.config.globalProperties.$echarts = echarts
app.config.globalProperties.productionTip = false;

app.use(store);
app.use(router);
app.use(vue3SeamlessScroll);
app.mount('#app')
